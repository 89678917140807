import { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import useInput from "../../hooks/userInput";
import { LuWallet } from "react-icons/lu";

import classes from "./Wallet.module.css";
import { getWallet, updateWallet } from "../../api/api";
import Spinner from "../UI/Spinner";
import AuthAlert from "../alerts/AuthAlert";

const Wallet = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertStatus, setAlertStatus] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [wallet, setWallet] = useState(null);
  const [walletId, setWalletId] = useState(null);
  const { jwt } = useCookies(["jwt"])[0];

  const {
    value: walletInput,
    enteredValueIsValid: walletInputIsValid,
    hasError: walletInputIsInvalid,
    valueInputChangedHandler: walletInputChangedHandler,
    valueInputBlurHandler: walletInputBlurHandler,
    prefillValue: walletPrefillValue,
  } = useInput((value) => value.trim() !== "");

  useEffect(() => {
    const fetchWallet = async () => {
      const res = await getWallet(jwt);

      if (res.status === "success") {
        setWallet(res.data.wallets[0].address);
        setWalletId(res.data.wallets[0]._id);
      }
    };
    fetchWallet();
  }, [jwt]);

  useEffect(() => {
    if (!wallet) return;
    walletPrefillValue(wallet);
  }, [wallet, walletPrefillValue]);

  let formIsValid = false;

  if (walletInputIsValid) {
    formIsValid = true;
  }

  const submitHandler = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const data = {
      address: walletInput,
    };
    const res = await updateWallet(data, walletId, jwt);

    if (res.status === "success") {
      setAlertMsg(res.message);
      setAlertStatus(true);
      setShowAlert(true);
    } else {
      setAlertMsg(res.message);
      setAlertStatus(false);
      setShowAlert(true);
    }
    setShowSpinner(false);
    setTimeout(() => {
      setShowAlert(false);
    }, 4000);
  };

  const walletInputClasses = walletInputIsInvalid
    ? `${classes.group} ${classes.invalid}`
    : classes.group;
  return (
    <form className={classes.form} onSubmit={submitHandler}>
      {showSpinner && <Spinner />}
      {showAlert && <AuthAlert message={alertMsg} status={alertStatus} />}
      <div className={walletInputClasses}>
        <label>Wallet Address</label>
        <div className={classes["input-group"]}>
          <LuWallet className={classes.icon} />
          <input
            type="text"
            value={walletInput}
            onChange={walletInputChangedHandler}
            onBlur={walletInputBlurHandler}
          />
        </div>
      </div>
      <div className={classes.action}>
        <button type="submit" disabled={!formIsValid}>
          Update Wallet
        </button>
      </div>
    </form>
  );
};

export default Wallet;
