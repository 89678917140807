import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FaAlignJustify } from "react-icons/fa";
import { FcUnlock } from "react-icons/fc";
import { BsPersonBadge } from "react-icons/bs";
import { FaRegFolder } from "react-icons/fa";
import { LuWallet } from "react-icons/lu";

import classes from "./SubNav.module.css";

const SubNav = () => {
  const navRef = useRef();
  const navBtnRef = useRef();
  const [navBtnState, setNavBtnState] = useState(true);

  const navHander = () => {
    navRef.current.classList.toggle(classes.open);
    navRef.current.classList.toggle(classes.close);
    setNavBtnState((prevState) => !prevState);
  };

  const navBtnClasses = navBtnState
    ? `${classes.slider} ${classes.rotate}`
    : `${classes.slider}`;

  return (
    <nav className={`${classes.nav} ${classes.open}`} ref={navRef}>
      <div onClick={navHander} ref={navBtnRef}>
        <FaAlignJustify className={navBtnClasses} />
      </div>
      <NavLink
        to="users"
        className={(navData) => (navData.isActive ? classes.active : "")}
        onClick={navHander}
      >
        <BsPersonBadge className={classes.icon} />
        Clients
      </NavLink>
      <NavLink
        to="investments"
        className={(navData) => (navData.isActive ? classes.active : "")}
        onClick={navHander}
      >
        <FaRegFolder className={classes.icon} />
        Investments
      </NavLink>
      <NavLink
        to="wallet"
        className={(navData) => (navData.isActive ? classes.active : "")}
        onClick={navHander}
      >
        <LuWallet className={classes.icon} />
        Wallet
      </NavLink>
      <NavLink
        to="password"
        className={(navData) => (navData.isActive ? classes.active : "")}
        onClick={navHander}
      >
        <FcUnlock className={classes.icon} />
        Change Password
      </NavLink>
    </nav>
  );
};

export default SubNav;
